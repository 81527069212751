<template functional>
  <a :href="props.item.url" target="_blank">
    <b-card class="client-card client-card--shadowborder">
      <b-card-text class="text-center">
        <div class="client-card__img-container px-5 py-5">
          <b-avatar
          rounded
            :src="props.item.logo.path"
            size="150px"
            class="client-card__avatar"
          ></b-avatar>
        </div>
        <div class="mb-5 mx-2">
          <span class="client-card__title">{{ props.item.name | truncate(20) }}</span>
        </div>
      </b-card-text>
    </b-card>
  </a>
</template>

<script>
export default {
  name: "ClientCard",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped></style>
